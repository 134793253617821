<template>
  <div class="w-100 pl-md-3 pr-md-3 mb-3">
    <table class="table table-responsive-lg rounded shadow-sm" v-background-3 v-color-1>
      <thead>
        <tr>
          <th scope="col">
            <base-select
              inputText="Groupe"
              v-model.number="societe_id"
              :options="data.societes"
              :modeIOS="true"
              :disabled="false"
            ></base-select>
          </th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 6).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 5).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 4).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 3).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 2).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().subtract("year", 1).format("YYYY") }}</th>
          <th scope="col" class="text-center">{{ $moment().format("YYYY") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="text-center">
          <th class="text-left" scope="row">Accident du travail avec arrêt</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'aaa_' + i" :class="{ 'text-danger': calc(i, 1) > 0 }">
            {{ calc(i, 1) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Accident du travail sans arrêt</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'asa_' + i" :class="{ 'text-danger': calc(i, 2) > 0 }">
            {{ calc(i, 2) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Accident de trajet avec arrêt</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'ata_' + i" :class="{ 'text-danger': calc(i, 3) > 0 }">
            {{ calc(i, 3) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Accident de trajet sans arrêt</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'ats_' + i" :class="{ 'text-danger': calc(i, 4) > 0 }">
            {{ calc(i, 4) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Accident bénin</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'ab_' + i" :class="{ 'text-danger': calc(i, 5) > 0 }">
            {{ calc(i, 5) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Presqu'accident</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'pa_' + i" :class="{ 'text-danger': calc(i, 6) > 0 }">
            {{ calc(i, 6) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Situation dangereuse</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'sd_' + i" :class="{ 'text-danger': calc(i, 7) > 0 }">
            {{ calc(i, 7) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Observation</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'obs_' + i" :class="{ 'text-danger': calc(i, 8) > 0 }">
            {{ calc(i, 8) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">Evènement RP</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'rp_' + i" :class="{ 'text-danger': calc(i, 9) > 0 }">
            {{ calc(i, 9) }}
          </td>
        </tr>
        <tr class="text-center">
          <th class="text-left" scope="row">ESR</th>
          <td v-for="i in [6, 5, 4, 3, 2, 1, 0]" :key="'esr_' + i" :class="{ 'text-danger': calc(i, 10) > 0 }">
            {{ calc(i, 10) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h5 class="mt-2 mb-2">Liste des évènements {{ $moment().format("YYYY") }}</h5>
      <div>
        <btn class="btn btn-primary mr-1" @click="exportEvenements()" :loading="loading" text="Exporter"/>
        <btn class="btn btn-primary" @click="createEvenements()" :loading="loading" text="Ajouter" icon="plus" />
      </div>    
    </div>

    <div class="mb-3">
      <div v-for="evenement in evenementsNew" :key="'evenement_new' + evenement.id" class="w-100">
        <evenementsItem :evenement="evenement" :actions="actions"></evenementsItem>
      </div>
    </div>

    <div v-for="evenement in evenementsFiltered" :key="'evenement' + evenement.id" class="w-100">
      <evenementsItem
        :evenement="evenement"
        :actions="actions"
        v-if="$moment(evenement.evenement_date).format('YYYY') === $moment().format('YYYY')"
      ></evenementsItem>
    </div>
    <div class="mt-3 mb-3 text-primary cursor-pointer" v-if="!old" @click="old = !old">Afficher l'historique</div>
    <div class="mt-3 mb-3 text-primary cursor-pointer" v-if="old" @click="old = !old">
      Masquer les années précédentes
    </div>
    <div v-if="old">
      <div v-for="evenement in evenementsFiltered" :key="'evenement' + evenement.id" class="w-100">
        <evenements-item
          :evenement="evenement"
          :actions="actions"
          v-if="$moment(evenement.evenement_date).format('YYYY') !== $moment().format('YYYY')"
        ></evenements-item>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import evenementsItem from "@/components/qsser/evenementsItem.vue";
import btn from "@/components/bases/Button.vue";
import BaseSelect from "@/components/bases/Select.vue";

export default {
  components: { btn, evenementsItem, BaseSelect },
  data() {
    return {
      old: false,
      loading: false,
      societe_id: null,
    };
  },
  mounted() {
    this.getEvenements();
  },
  methods: {
    ...mapActions({
      _getEvenements: "qsser/getEvenements",
      _createEvenements: "qsser/createEvenements",
      _exportEvenements: "qsser/exportEvenements",
    }),
    createEvenements: function () {
      this.loading = true;
      this._createEvenements().finally(() => (this.loading = false));
    },
    getEvenements: function () {
      this.loading = true;
      this._getEvenements()
        //.then(() => ())
        .finally(() => (this.loading = false));
    },
    exportEvenements: function () {
      this.loading = true;
      this._exportEvenements().finally(() => (this.loading = false));
    },
    calc: function (subtractYear, type_id) {
      let counter = [];
      if (this.societe_id) {
        counter = this.evenements.filter(
          (e) =>
            e.evenement_societe_id == this.societe_id &&
            e.evenement_type_id == type_id &&
            this.$moment(e.evenement_date).year() == this.$moment().subtract("year", subtractYear).year()
        );
      } else {
        counter = this.evenements.filter(
          (e) =>
            e.evenement_type_id == type_id &&
            this.$moment(e.evenement_date).year() == this.$moment().subtract("year", subtractYear).year()
        );
      }

      return counter.length;
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
    }),
    ...mapMultiRowFields("qsser", ["evenements", "actions"]),
    evenementsNew() {
      return this.evenements.filter((e) => e.evenement_new == true).reverse();
    },
    evenementsFiltered() {
      let rep = this.evenements.filter((e) => e.evenement_new != true);
      if (this.societe_id) rep = rep.filter((e) => e.evenement_societe_id == this.societe_id);
      return rep;
    },
  },
};
</script>
<style></style>
