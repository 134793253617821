<template>
  <container-list-extend title="Visite" class="d-flex flex-column w-100">
    <template v-slot:title>
      <img :src="img" style="height: 2rem" class="ml-2 mr-1" />
      <div class="w-100 d-flex justify-content-between align-items-center p-2">
        <div>
          <div class="mr-2">
            <description-from-list
              v-if="evenement.evenement_type_id"
              class=""
              :value="evenement.evenement_type_id"
              :data="data.evenementTypes"
              text="Evènement"
            ></description-from-list>
            <span class="text-muted"> n°{{ evenement.id }}</span>
          </div>
          <div class="text-muted pr-2 pr-md-5 deux-lignes">
            <span v-if="!evenement.evenement_remarques" class="text-primary">Nouvel évènement à saisir</span>
            <small>{{ evenement.evenement_remarques }}</small>
          </div>
        </div>
        <div>
          <small class="text-muted">
            {{ $moment(evenement.evenement_date).format("DD/MM/YYYY") }}
          </small>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="d-flex align-items-center p-3">
        <menuItem
          title="informations"
          @click="tab = 'informations'"
          :active="tab === 'informations'"
          icon="info"
        ></menuItem>
        <menuItem title="actions" @click="tab = 'actions'" :active="tab === 'actions'" icon="check-circle"></menuItem>
        <menuItem title="documents" @click="tab = 'documents'" :active="tab === 'documents'" icon="file"></menuItem>
      </div>
      <base-form
        deleteBtn
        :loading="loading"
        @click="updateEvenement(evenement)"
        @delete="deleteEvenement(evenement)"
        v-if="tab === 'informations'"
      >
        <base-form-row row>
          <base-inputDatePicker
            v-model="evenement.evenement_date"
            inputText="Date"
            :errors="feedback.evenement_date"
          ></base-inputDatePicker>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Société"
            v-model.number="evenement.evenement_societe_id"
            :options="data.societes"
            :errors="feedback.evenement_societe_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Type"
            v-model.number="evenement.evenement_type_id"
            :options="data.evenementTypes"
            :errors="feedback.evenement_type_id"
          ></base-select>
          <base-select
            inputText="Risque"
            v-model.number="evenement.evenement_risque_id"
            :options="data.evenementRisques"
            :errors="feedback.evenement_risque_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-select
            inputText="Classement"
            v-model.number="evenement.evenement_classement_id"
            :options="data.evenementClassements"
            :errors="feedback.evenement_classement_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input-area
            v-model="evenement.evenement_remarques"
            text="Description"
            :errors="feedback.evenement_remarques"
          ></base-input-area>
        </base-form-row>
      </base-form>
      <div class="w-100 p-md-3" v-if="tab === 'documents'">
        <uploader
          v-if="evenement.id"
          :id="'document_qsser_evenements' + evenement.id"
          url="/upload/document"
          title="Déposer vos fichiers"
          :file_system="'QSSER/evenements'"
          :file_field="'evenement'"
          :file_key="evenement.id"
        ></uploader>
      </div>
    </template>
    <template v-slot:actions>
      <actions-linked v-if="tab === 'actions'" :origine_system="19" :origine_id="evenement.id"></actions-linked>
    </template>
  </container-list-extend>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { errHandler } from "@/helpers.js";
import ContainerListExtend from "@/components/containers/ContainerListExtend.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseInputDatePicker from "@/components/bases/InputDatePicker.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseSelect from "@/components/bases/Select.vue";
import Uploader from "@/components/bases/Uploader.vue";
import actionsLinked from "@/components/qsser/actionsLinked.vue";
import menuItem from "@/components/qsser/menuItem.vue";
import DescriptionFromList from "@/components/bases/DescriptionFromList";

export default {
  components: {
    ContainerListExtend,
    BaseForm,
    BaseFormRow,
    BaseInputDatePicker,
    BaseInputArea,
    BaseSelect,
    actionsLinked,
    Uploader,
    menuItem,
    DescriptionFromList,
  },
  data() {
    return {
      old: false,
      loading: false,
      feedback: {},
      tab: "informations",
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      _updateEvenement: "qsser/updateEvenements",
      _deleteEvenement: "qsser/deleteEvenements",
    }),
    updateEvenement: function (evenement) {
      this.feedback = {};
      this.loading = true;
      this._updateEvenement(evenement)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    deleteEvenement: function (evenement) {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.feedback = {};
      this.loading = true;
      this._deleteEvenement(evenement)
        .catch((error) => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  props: ["evenement", "actions"],
  computed: {
    ...mapGetters({
      data: "qsser/data",
    }),
    img() {
      if (this.evenement.evenement_risque_id == 19) return "/images/adr_route.svg";
      if (this.evenement.evenement_risque_id == 23) return "/images/adr_rp.svg";
      return "/images/adr_general.svg";
    },
  },
};
</script>
<style>
.deux-lignes {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
